import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { FaCloud, FaQuestionCircle } from 'react-icons/fa';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelCard from 'components/PanelCard';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import PanelDropdown from './components/PanelDropdown';

import { timezones } from './timezones';

import { Container, Info } from './styles';

interface LoadContainerResponse {
  data: {
    idContainer: string;
    container: string;
    apacheUser: string;
    timezone: string;
    virtualCpu: string;
    memory: string;
    phpIni: {
      session_auto_start: string;
    };
    redis: string;
  };
}

interface Container {
  idContainer: string;
  container: string;
  apacheUser: string;
  timeZone: string;
  virtualCpu: string;
  memory: string;
  sessionAutoStart: string;
  redis: string;
}

interface CloudSettingsFormValues {
  timezone: string;
  apacheUser: string;
  sessionAutoStart: string;
  redis: string;
}

const CloudSettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rebooting, setRebooting] = useState(false);
  const [showRebootModal, setShowRebootModal] = useState(false);

  const [container, setContainer] = useState<Container>({
    idContainer: '',
    container: '',
    apacheUser: '',
    timeZone: '',
    virtualCpu: '0',
    memory: '0',
    sessionAutoStart: '',
    redis: '',
  });

  useEffect(() => {
    async function loadContainer() {
      try {
        setLoading(true);
        setError(false);

        const { data } = await api.get<LoadContainerResponse>(
          `painel/v1/site/${idSite}/container`,
        );

        const containerData = data.data;

        setContainer({
          idContainer: containerData.idContainer,
          container: containerData.container,
          apacheUser: containerData.apacheUser,
          timeZone: containerData.timezone,
          virtualCpu: containerData.virtualCpu,
          memory: containerData.memory,
          sessionAutoStart: containerData.phpIni.session_auto_start,
          redis: containerData.redis,
        });
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadContainer();
  }, [idSite]);

  async function editContainer(values: CloudSettingsFormValues) {
    try {
      const { timezone, apacheUser, sessionAutoStart, redis } = values;

      const params = {
        timezone,
        apacheUser,
        phpini: {
          session_auto_start: sessionAutoStart,
        },
        redis,
      };

      await api.put(`painel/v1/site/${idSite}/container`, params);

      toast.fire(t('pages:cloudSettings.editSettingsSuccess'));
    } catch (err) {
      swal.fire(t('pages:cloudSettings.editSettingsFailure'));
    }
  }

  async function rebootContainer() {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        icon: 'warning',
        html: t('pages:cloudSettings.rebootWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setRebooting(true);

      await api.post(`painel/v1/site/${idSite}/container/reinicializacao`);

      setShowRebootModal(true);

      toast.fire(t('pages:cloudSettings.rebootSuccess'));
    } catch (err) {
      swal.fire(t('pages:cloudSettings.rebootFailure'));
    } finally {
      setRebooting(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <Row className="my-4">
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>CPU</h6>
                <Info className="blue">
                  {parseInt(container.virtualCpu, 10).toFixed(1)}v
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('common:memory')}</h6>
                <Info className="blue">
                  {parseInt(container.memory, 10).toFixed(2)}GB
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>

      <FormWrapper>
        <FormHeader
          title={t('pages:cloudSettings.title')}
          description={t('pages:cloudSettings.description')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-do-plano-cloud"
        />

        <Formik
          enableReinitialize
          initialValues={{
            timezone: container.timeZone,
            apacheUser: container.apacheUser,
            sessionAutoStart: container.sessionAutoStart,
            redis: container.redis === null ? 'DISABLE' : 'ENABLE',
          }}
          onSubmit={editContainer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:phpSettings.basicSettings')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:cloudSettings.timezone')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-">
                          {t('pages:cloudSettings.timezoneTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <PanelDropdown
                      selectedValue={props.values.timezone}
                      values={timezones}
                      onChange={value => props.setFieldValue('timezone', value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:cloudSettings.apacheUser')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-apache" className="lg-tooltip">
                          {t('pages:cloudSettings.apacheUserTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="apacheUser-true"
                      name="apacheUser"
                      label={container.container}
                      value={container.container}
                      checked={props.values.apacheUser === container.container}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.apacheUser && !!props.errors.apacheUser
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="apacheUser-false"
                      name="apacheUser"
                      label="www-data"
                      value="www-data"
                      checked={props.values.apacheUser === 'www-data'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.apacheUser && !!props.errors.apacheUser
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.apacheUser}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:cloudSettings.sessionAutoStart')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-session" className="lg-tooltip">
                          {t('pages:cloudSettings.sessionAutoStartTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="sessionAutoStart-ativo"
                      name="sessionAutoStart"
                      label="Ativo"
                      value="1"
                      checked={props.values.sessionAutoStart === '1'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.sessionAutoStart &&
                        !!props.errors.sessionAutoStart
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="sessionAutoStart-inativo"
                      name="sessionAutoStart"
                      label="Inativo"
                      value="0"
                      checked={props.values.sessionAutoStart === '0'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.sessionAutoStart &&
                        !!props.errors.sessionAutoStart
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.sessionAutoStart}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:cloudSettings.redis')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-redis" className="lg-tooltip">
                          {t('pages:cloudSettings.redisTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="redis-ativo"
                      name="redis"
                      label="Ativo"
                      value="ENABLE"
                      checked={props.values.redis === 'ENABLE'}
                      onChange={props.handleChange}
                      isInvalid={!!props.touched.redis && !!props.errors.redis}
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="redis-inativo"
                      name="redis"
                      label="Inativo"
                      value="DISABLE"
                      checked={props.values.redis === 'DISABLE'}
                      onChange={props.handleChange}
                      isInvalid={!!props.touched.redis && !!props.errors.redis}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.redis}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  disabled={
                    props.isSubmitting || !props.isValid || !props.dirty
                  }
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:cloudSettings.rebootTitle')}
          titleTooltip={t('pages:cloudSettings.rebootTooltip')}
          description={t('pages:cloudSettings.rebootDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-do-plano-cloud/#Reboot"
        />
        <Card.Body className="pt-0">
          <PanelButton
            size="sm"
            disabled={rebooting}
            onClick={() => rebootContainer()}
          >
            {t('pages:cloudSettings.reboot')}
          </PanelButton>
        </Card.Body>
      </FormWrapper>

      <Modal
        size="lg"
        centered
        show={showRebootModal}
        onHide={() => setShowRebootModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('common:warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('pages:cloudSettings.rebootSuccess')}.</p>
          <p>{t('pages:cloudSettings.rebootModal.p1')}</p>
          <p>{t('pages:cloudSettings.rebootModal.p2')}</p>

          <p>
            <Trans i18nKey="pages:cloudSettings.rebootModal.p3">
              text
              <Link to="/cloud/monitoramento-recursos" />
            </Trans>
          </p>

          <p>
            <Trans i18nKey="pages:cloudSettings.rebootModal.p4">
              text
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hostnet.com.br/info/melhore-o-desempenho-do-seu-wordpress/"
              >
                link
              </a>
            </Trans>
          </p>

          <p>
            <Trans i18nKey="pages:cloudSettings.rebootModal.p5">
              text
              <Link to="/site/editar-plano" />
            </Trans>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <PanelButton
            variant="secondary"
            onClick={() => setShowRebootModal(false)}
          >
            {t('common:close')}
          </PanelButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CloudSettings;
